import React from 'react'
import Title from '../Components/Title'
// import Categories from '../Components/Categories'
import MenuItems from '../Components/MenuItems'
import portfolios from '../Components/allportfolios'
import { useState } from 'react'

// const allCategories = ['All', ...new Set(portfolios.map(item => item.category))];


function PortfolioPage() {
   // const [categories] = useState(allCategories);
     const [menuItems, setMenuItems] = useState(portfolios);

     const filter = (category) =>{
        if(category === 'All'){
            setMenuItems(portfolios)
            return;
        }
        const filteredData = portfolios.filter((item)=>{
           return item.category === category;
        })
        setMenuItems(filteredData);
    }

    return (
        <div className="PortfolioPage">
            <div className="title">
                <Title title={'Portfolio'} span={'portfolios'}/>
            </div>
            <div className="portfolios-data">
                <MenuItems menuItem={menuItems}/>
            </div>
        </div>
    )
}

export default PortfolioPage
